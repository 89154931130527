// import { FaPrint } from 'react-icons/fa';
// import { clone } from 'lodash';
// import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
// import download from 'downloadjs';
// import firebase from 'gatsby-plugin-firebase';
// import { useSelector } from '../../contexts/ResumeContext';
// import axios from 'axios';
import BaseModal from '../BaseModal';
// import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';
// import { b64toBlob } from '../../utils';
import UpgradeIcon from '../../../static/images/upgrade-icon.svg';
import Tick from '../../../static/images/green-tick.svg';
import UserContext from '../../contexts/UserContext';
import _config from '../../utils/config';

const SubscriptionModal = () => {
  //   const state = useSelector();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [activeSubscriptions, setSubscriptions] = useState('Monthly');
  //   const [isLoadingSingle, setLoadingSingle] = useState(false);
  //   const [isLoadingMulti, setLoadingMulti] = useState(false);

  const { emitter, events } = useContext(ModalContext);
  const body = {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    maxWidth: '1202px',
  };
  // const plan = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'left',
  //   justifyContent: 'center',
  //   height: '610px',
  //   width: '55%',
  //   padding: '30px',
  //   margin: '20px',
  //   borderRadius: 16,
  //   // background: "#eff3f3",
  //   // border: "1px solid black",
  //   textAlign: 'left',
  //   fontFamily: 'SF Pro Display',
  //   fontStyle: 'normal',
  //   fontWeight: 'normal',
  // };

  // const plan2 = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: '45%',

  //   // height: "350px",
  //   padding: '0',
  //   margin: '0px',
  //   borderRadius: '0 16px 16px 0',
  //   background: '#54d9fb',
  //   color: '#fff',
  //   // border: "2px solid #0351FF",
  //   textAlign: 'center',
  //   fontFamily: 'SF Pro Display',
  //   fontStyle: 'normal',
  //   fontWeight: 'normal',
  // };

  useEffect(() => {
    const unbind = emitter.on(events.SUBSCRIPTION_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  //   const handleOpenPrintDialog = () => {
  //     if (typeof window !== `undefined`) {
  //       window && window.print();
  //     }
  //   };

  // const handleMonthlySubscriptions = async () => {
  //   let userId = '';
  //   const usersInfo = localStorage.getItem('user');
  //   if (usersInfo) {
  //     const { uid } = JSON.parse(usersInfo);
  //     userId = uid;
  //   }
  //   const config = {
  //     method: 'POST',
  //     url: 'http://localhost:3008/create-checkout-session',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     data: JSON.stringify({
  //       priceId: 'price_1K5R0UG91f9d1rKgoFh3J7iD',
  //       userId,
  //     }),
  //   };

  //   axios(config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const { url } = res.data.data;
  //         window.location.href = `${url}`;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleYearlySubscriptions = async () => {
  //   let userId = '';
  //   const usersInfo = localStorage.getItem('user');
  //   if (usersInfo) {
  //     const { uid } = JSON.parse(usersInfo);
  //     userId = uid;
  //   }
  //   const config = {
  //     method: 'POST',
  //     url: 'http://localhost:3008/create-checkout-session',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     data: JSON.stringify({
  //       priceId: 'price_1K5ufaG91f9d1rKgbHAS6CZK',
  //       userId,
  //     }),
  //   };

  //   axios(config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const { url } = res.data.data;
  //         window.location.href = `${url}`;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const handleSwitchSubscription = (event) => {
    const currentSubs = event.currentTarget.getAttribute('current-subs');
    if (currentSubs === 'Yearly') {
      setSubscriptions('Monthly');
    } else {
      setSubscriptions('Yearly');
    }
  };

  const { setPaymentDetails } = useContext(UserContext);

  const onPriceClick = () => {
    if (activeSubscriptions === 'Monthly') {
      setPaymentDetails({
        priceId: _config.subscriptions.monthly,
        priceAmount: _config.subscriptionsPrices.monthly,
        priceType: t('pricing.step2.card.monthly'),
      });
    } else if (activeSubscriptions === 'Yearly') {
      setPaymentDetails({
        priceId: _config.subscriptions.yearly,
        priceAmount: _config.subscriptionsPrices.yearly,
        priceType: t('pricing.step2.card.yearly'),
      });
    }
    navigate(`/pricing/step2`);
  };

  return (
    <BaseModal
      width="60%"
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.export.heading')}
    >
      <>
        <div style={body}>
          <div className="justify-center items-center hidden md:flex  md:w-2/6">
            <img src={UpgradeIcon} alt="upgrade icon" />
          </div>
          <div className="flex flex-col space-y-4 justify-center items-start px-1 md:px-5 w-full md:w-4/6">
            <h1 className="text-[#293F67] font-semibold text-[24px]">
              {t('modals.subscriptionDownload.title')}
            </h1>
            <p className="text-[#53587E] text-[16px]">
              {t('modals.subscriptionDownload.description')}
            </p>

            <p className="text-[#293F67] text-[16px]">
              {t('modals.subscriptionDownload.also')}
            </p>

            <div className="flex space-x-3">
              <img src={Tick} alt="green tick" />
              <p className="text-[#53587E] text-[16px]">
                {t('modals.subscriptionDownload.features.0')}
              </p>
            </div>
            {/* <div className="flex space-x-3">
              <img src={Tick} alt="green tick" />
              <p className="text-[#53587E] text-[16px]">
                {t('modals.subscriptionDownload.features.1')}
              </p>
            </div> */}
            <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 space-y-3 md:space-y-0">
              <button
                onClick={onPriceClick}
                className="bg-[#3346D3] p-4 text-white text-[14px] rounded-2xl shadow-md hover:no-underline"
              >
                {t('shared.buttons.upgrade')}
              </button>

              {activeSubscriptions === 'Yearly' && (
                <button
                  onClick={handleSwitchSubscription}
                  current-subs="Yearly"
                  className=" p-4 text-[14px] rounded-2xl border-[1px] border-solid border-[#c9c9c9] shadow-md"
                >
                  {t('shared.buttons.changeToMonthly')}
                </button>
              )}
              {activeSubscriptions === 'Monthly' && (
                <button
                  onClick={handleSwitchSubscription}
                  current-subs="Monthly"
                  className=" p-4 text-[14px] rounded-2xl border-[1px] border-solid border-[#c9c9c9] shadow-md"
                >
                  {t('shared.buttons.changeToYearly')}
                </button>
              )}
            </div>
          </div>

          {/* <div style={plan}>
            <h2>Subscribe to continue</h2>
            <br />
            <h2>FAST 50</h2>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Cras justo odio</li>
              <li className="list-group-item">Dapibus ac facilisis in</li>
              <li className="list-group-item">Morbi leo risus</li>
              <li className="list-group-item">Porta ac consectetur ac</li>
              <li className="list-group-item">Vestibulum at eros</li>
            </ul>
            Ideal for small and medium business Unlimited jobs Unlimited
            applicants Contact up to 50 candidates per month Also includes:
            Promotion in job alert emails to candidates Screening questions for
            filtering applications Recommended candidates that you can invite to
            apply Need help with a payment or a custom plan? Give us a call at
            +44 20 7031 5796 or send a message to the Support Team
          </div>
          <div style={plan2}>
           
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              $299.99 per year, saved 30% <br /> You can cancel anytime{' '}
            </p>
            <Button
              size="lg"
              variant="primary"
              data-id="price_1Jwo6cI1m3JUvgDKWgmlQ2Ha"
              onClick={handleYearlySubscriptions}
            >
              Subscription
            </Button>
            <Button
              current-subs="Yearly"
              onClick={handleSwitchSubscription}
              // style={{ cursor: 'pointer', color: 'rgb(73 103 139)' }}
            >
              {' '}
              Switch to monthly billing{' '}
            </Button>
          </div> */}
        </div>
      </>

      {/* {activeSubscriptions === 'Monthly' && (
        <div style={body}>
          <div style={plan}>
            <h2>Subscribe to continue</h2>
            <br />
            <h2>FAST 50</h2>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Cras justo odio</li>
              <li className="list-group-item">Dapibus ac facilisis in</li>
              <li className="list-group-item">Morbi leo risus</li>
              <li className="list-group-item">Porta ac consectetur ac</li>
              <li className="list-group-item">Vestibulum at eros</li>
            </ul>
    
            Recommended candidates that you can invite to apply Need help with a
            payment or a custom plan? Give us a call at +44 20 7031 5796 or send
            a message to the Support Team
          </div>
          <div style={plan2}>
        
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              $49.99 per month <br /> You can cancel anytime{' '}
            </p>
            <Button
              size="lg"
              variant="primary"
              data-id="price_1Jwo5DI1m3JUvgDKc9AU4xo5"
              onClick={handleMonthlySubscriptions}
            >
              Subscription
            </Button>
            <br />
            <Button
              current-subs="Monthly"
              onClick={handleSwitchSubscription}
              style={{ cursor: 'pointer', color: 'rgb(73 103 139)' }}
            >
              {' '}
              Switch to yearly billing{' '}
            </Button>
          </div>
        </div>
      )} */}
    </BaseModal>
  );
};

export default memo(SubscriptionModal);
