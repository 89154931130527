import { navigate, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import BaseModal from './BaseModal';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
import google from '../../static/images/google-logo.svg';
import facebook from '../../static/images/fb-logo.svg';
import linkedin from '../../static/images/linkedin.svg';
import arrow from '../../static/images/greater-arrow.png';
import logo from '../../static/images/logo-white.svg';
import banner from '../../static/images/login-banner.svg';
import { linkedInLogin } from '../services/firebaseService';

const ProcessingButton = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center align-middle">
      <svg
        className="animate-spin -inline-block w-6 h-6 border-4 rounded-full mr-2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx={12}
          cy={12}
          r={10}
          stroke="currentColor"
          strokewidth={4}
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span>{t('shared.buttons.processing')}</span>
    </div>
  );
};

const AuthModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);
  const [isLoadingFacebook, setLoadingFacebook] = useState(false);
  const [isLoadingAnonymous, setLoadingAnonymous] = useState(false);
  const [isLoadingEmail, setLoadingEmail] = useState(false);
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const { emitter, events } = useContext(ModalContext);
  const {
    user,
    loginWithGoogle,
    loginAnonymously,
    logout,
    loginWithEmail,
    loginWithFacebook,
  } = useContext(UserContext);

  useEffect(() => {
    const unbind = emitter.on(events.AUTH_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleSignInWithFacebook = async () => {
    setLoadingFacebook(true);
    await loginWithFacebook();
    setLoadingFacebook(false);
  };

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    await loginWithGoogle();
    setLoadingGoogle(false);
  };

  const handleSignInWithEmail = async () => {
    setLoadingEmail(true);
    await loginWithEmail(loginData.username, loginData.password);
    setLoadingEmail(false);
  };

  const handleSignInAnonymously = async () => {
    setLoadingAnonymous(true);
    await loginAnonymously();
    setLoadingAnonymous(false);
  };

  const handleGotoApp = () => {
    navigate('/app/dashboard');
    setOpen(false);
  };

  const getTitle = () =>
    user
      ? t('modals.auth.welcome', { name: user.displayName || 'Agent 47' })
      : t('modals.auth.whoAreYou');

  const getMessage = () =>
    user ? t('modals.auth.loggedInText') : t('modals.auth.loggedOutText');

  const loggedInAction = (
    <>
      <Button outline className="mr-8" onClick={logout}>
        {t('shared.buttons.logout')}
      </Button>
      <Button title="" onClick={handleGotoApp}>
        {t('landing.hero.goToApp')}
      </Button>
    </>
  );

  const onChanger = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    // console.log(loginData)
  };

  const loggedOutAction = (
    <>
      <div className="flex pt-[20px] pb-[20px] w-full md:w-2/4 bg-[#4258FF] flex-col justify-center items-center align-middle space-y-6 rounded-l-none md:rounded-l-2xl">
        <div className="flex">
          <img src={logo} alt="logo" />
          <Link
            to="/"
            className="font-semibold  text-[17.7px] ml-3 text-white hover:no-underline"
          >
            SimpleCV
          </Link>
        </div>
        <h2 className="font-semibold  text-[27px] text-white">
          {t('modals.authModal.title')}
        </h2>
        <div>
          <button
            className="flex items-center justify-between px-6 w-[280px] border-[1px] border-[#B1BBFF] py-3 rounded-lg text-[10px] font-medium text-[#202970] bg-white"
            isLoading={isLoadingGoogle}
            onClick={linkedInLogin}
          >
            <img src={linkedin} alt="google" className="w-6 h-6 " />{' '}
            {t('modals.authModal.loginWith')} LinkedIn{' '}
            <img src={arrow} alt="arrow" />
          </button>

          <div className="flex my-3">
            <button
              className="flex items-center justify-between px-6 w-[280px] border-[1px] border-[#B1BBFF] py-3 rounded-lg text-[10px] font-medium text-white"
              isLoading={isLoadingGoogle}
              onClick={handleSignInWithGoogle}
            >
              <img src={google} alt="google" />{' '}
              {t('modals.authModal.loginWith')} Google{' '}
              <img src={arrow} alt="arrow" />
            </button>

            <button
              className="ml-8 hidden"
              isLoading={isLoadingAnonymous}
              onClick={handleSignInAnonymously}
            >
              {t('modals.auth.buttons.anonymous')}
            </button>
          </div>

          <button
            className="flex items-center justify-between my-3 px-6 w-[280px] border-[1px] border-[#B1BBFF] py-3 rounded-lg text-[10px] font-medium text-[#202970] bg-white"
            isLoading={isLoadingFacebook}
            onClick={handleSignInWithFacebook}
          >
            <img src={facebook} alt="facebook" />{' '}
            {t('modals.authModal.loginWith')} Facebook{' '}
            <img src={arrow} alt="arrow" />
          </button>
        </div>
        <div className="flex items-center w-[280px] text-[#B1BBFF]">
          <div className="flex-grow w-3/12 border-t border-[#B1BBFF]" />
          <p className="w-6/12 text-center text-[10px]">
            {t('modals.authModal.orLoginWith')}
          </p>
          <div className="flex-grow w-3/12 border-t border-[#B1BBFF]" />
        </div>

        <div className="flex flex-col w-[280px]">
          <p className="mb-1 text-white text-[14px] font-semibold">
            {t('modals.authModal.username.title')}
          </p>
          <input
            onChange={(e) => onChanger(e)}
            name="username"
            className="bg-transparent rounded-lg border-[1px] border-[#B1BBFF] border-solid p-3 w-full placeholder:text-[#B1BBFF] placeholder:text-[10px] text-white focus-visible:outline-none"
            placeholder={t('modals.authModal.username.placeholder')}
            input="text"
          />
          <p className="mb-1 mt-4 text-white text-[14px] font-semibold">
            {t('modals.authModal.password.title')}
          </p>
          <input
            onChange={(e) => onChanger(e)}
            name="password"
            className="bg-transparent rounded-lg border-[1px] border-[#B1BBFF] border-solid p-3 w-full placeholder:text-[#B1BBFF] placeholder:text-[10px] text-white focus-visible:outline-none"
            placeholder={t('modals.authModal.password.placeholder')}
            input="password"
            type="password"
          />
          <div className="flex  w-full justify-between mt-2">
            <div className="flex items-center align-middle">
              <input
                type="checkbox"
                name="remember"
                className="w-4 bg-[#4258FF] mr-2"
              />
              <p className="text-[#B1BBFF] text-[10px] font-normal">
                {t('modals.authModal.keepMeLoggedIn')}
              </p>
            </div>
            <Link
              to=""
              className="text-[#B1BBFF] text-[10px] font-normal hover:no-underline"
            >
              {t('modals.authModal.forgotPassword')}
            </Link>
          </div>
        </div>

        <button
          onClick={() => handleSignInWithEmail()}
          className="bg-white text-[#4258FF] font-semibold text-[13px] w-[280px] rounded-lg py-3"
        >
          {isLoadingGoogle ||
          isLoadingFacebook ||
          isLoadingAnonymous ||
          isLoadingEmail ? (
            <ProcessingButton />
          ) : (
            t('shared.buttons.login')
          )}
        </button>

        <div>
          <p className="text-[#B1BBFF] text-[14px]">
            {t('modals.authModal.didNotSignup')}{' '}
            <Link to="/signup" className="text-white text-[14px] font-semibold">
              {t('shared.buttons.signup')}
            </Link>
          </p>
        </div>
      </div>
      <div
        className="hidden md:flex w-2/2 rounded"
        style={{
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          overflow: 'hidden',
          border: 'none',
          background: 'none',
        }}
      >
        <img
          src={banner}
          alt="banner"
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
    </>
  );

  return (
    <>
      <BaseModal
        title={getTitle()}
        state={[open, setOpen]}
        action={user ? loggedInAction : loggedOutAction}
      >
        <p className="leading-loose">{getMessage()}</p>
      </BaseModal>
    </>
  );
};

export default memo(AuthModal);
