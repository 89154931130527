import { Backdrop, Fade, Modal } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, {
  forwardRef,
  memo,
  useContext,
  useImperativeHandle,
} from 'react';
import UserContext from '../contexts/UserContext';
import Button from '../components/shared/Button';
import { handleKeyUp } from '../utils';
import * as styles from './BaseModal.module.css';

const BaseModal = forwardRef(
  ({ title, state, children, action, hideActions = false, onDestroy }, ref) => {
    const [open, setOpen] = state;
    const { t } = useTranslation();

    const { user } = useContext(UserContext);

    const handleClose = () => {
      setOpen(false);

      setTimeout(() => {
        isFunction(onDestroy) && onDestroy();
      }, 2050);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    return (
      <Modal
        open={open}
        closeAfterTransition
        onClose={handleClose}
        className={styles.root}
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <>
            {user ? (
              <div className={`${styles.modal}`}>
                <div className={styles.title}>
                  <h2 className="font-sans font-700">{title}</h2>
                  <MdClose
                    size="18"
                    tabIndex="0"
                    onClick={handleClose}
                    onKeyUp={(e) => handleKeyUp(e, handleClose)}
                  />
                </div>

                <div className={styles.body}>{children}</div>

                {!hideActions && (
                  <div className={styles.actions}>
                    <Button
                      outline
                      className="mr-8 border-none font-sans !text-[14px]"
                      onClick={handleClose}
                    >
                      {t('shared.buttons.cancel')}
                    </Button>
                    {action}
                  </div>
                )}
              </div>
            ) : (
              <Fade in={open}>
                <div className="flex mx-auto w-full md:w-[800px]">
                  {action}
                  <button
                    onClick={handleClose}
                    className="relative w-0 bottom-[220px] right-[20px] md:bottom-[264px] md:right-[20px] mt-2 text-3xl"
                  >
                    x
                  </button>
                </div>
              </Fade>
            )}
          </>
        </Fade>
      </Modal>
    );
  },
);

export default memo(BaseModal);
