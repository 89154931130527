export default {
  subscriptions: {
    weekly: 'price_1LOj2rG0V3YXI7FWmJb9nJEW',
    monthly: 'price_1LOjG9G0V3YXI7FWedqtkcCG',
    yearly: 'price_1LOjITG0V3YXI7FWWN225gxW',
  },
  subscriptionsPrices: {
    weekly: 2.99,
    monthly: 9.99,
    yearly: 29.99,
  },
};
