import axios from 'axios';

async function onLinkedInRedirect(search) {
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  if (error) throw new Error(error);
  if (!code) return {};
  const {
    data: { user, contact },
  } = await axios.get(
    `https://asia-east2-resume-maker-cbcc7.cloudfunctions.net/linkedin/oauth-user`,
    {
      params: { code },
    },
  );
  const provider = localStorage.getItem('socialProvider');
  localStorage.removeItem('socialProvider');
  const [emailHandle] = contact.elements;
  const {
    'displayImage~': { elements = [] },
  } = user.profilePicture;

  let photo = '';
  if (elements[1]) {
    if (elements[1].identifiers[0]) {
      if (elements[1].identifiers[0].identifier) {
        photo = elements[1].identifiers[0].identifier;
      }
    }
  }
  let email = '';
  if (emailHandle) {
    if (emailHandle['handle~']) {
      email = emailHandle['handle~'].emailAddress;
    }
  }

  return {
    provider,
    linkedinID: user.id,
    displayName: `${user.localizedFirstName} ${user.localizedLastName}`,
    photoURL: photo,
    email,
    accessToken: user.access_token,
  };
}

const linkedInLogin = async () => {
  try {
    const { data } = await axios.get(
      'https://asia-east2-resume-maker-cbcc7.cloudfunctions.net/linkedin/oauth-url',
    );
    window.location.href = data.oauthURL;
  } catch (e) {
    console.log(e);
  }
};

export { onLinkedInRedirect, linkedInLogin };
